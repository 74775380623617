import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import tourPackagesService from "services/admin/tour-packages.service";
import attributesService from "services/admin/attributesService";
import { snack } from "components/Snack/Snack";
import hotelsService from "services/admin/hotelsService";
import flightsService from "services/admin/flightsService";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Button, Dialog, FormControl, InputLabel, MenuItem, Select, Table, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { toNativeDate } from "helpers/dateHelper";
import { toFormData } from "helpers/formUtils";
import _ from "lodash";
import { FlightsDialog } from "components/FlightsDialog";
import { useState } from "react";
import tourScheduleService from "services/admin/tourScheduleService";

/**
 * TODO:
 * - Добавить валидация для начала и конца. Конца не может быть ранее чем начало
 * - Не отображать список всех рейсов в форме а сделать как выборку через dropdown
 * - Отели также
 * - Статус по умолчанию: Активный
 * - Изменить название кнопки на Сохранить/Изменить тур
 * - Валидация: Дата начало тура не может меньше сегодняшнего дня
 * - Валидация: Стоимость тура не может быть буквами, и меньше 100$
 * - Отели должны быть отдельной таблицей
 * - В детали тура возвращать список отелей
 * - В детали тура возвращать список рейсов туда и обратно
 * - Атрибуты связка с турам должны быть в отдельной таблице также
 * - Кнопка Save должно быть неактивный если не прошла валидацию
 */
const TourPackageForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { tourPackage, attributes, hotels, flights, tourSchedules },
}) => {

  flights.data = flights.data.filter(f => f.status === 'active')

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors, dirtyFields, isDirty, isValid, isSubmitting, isSubmitted },
    watch,
    unregister
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      photo: tourPackage ? tourPackage.photo : '',
      name: tourPackage ? tourPackage.name : '',
      description: tourPackage ? tourPackage.description : '',
      flightsTo: tourPackage ? tourPackage.flightsTo : [],
      returnFlights: tourPackage ? tourPackage.returnFlights : [],
      price: tourPackage ? tourPackage.price : '',
      childPrice: tourPackage ? tourPackage.childPrice : '',
      infantPrice: tourPackage ? tourPackage.infantPrice : '',
      startDate: tourPackage ? tourPackage.startDate : toNativeDate(new Date()),
      endDate: tourPackage ? tourPackage.endDate : toNativeDate(new Date()),
      attributes: tourPackage ? tourPackage.attributes.map(a => a.id) : [],
      hotels: tourPackage ? tourPackage.hotels.map(h => h.id) : [],
      status: tourPackage ? tourPackage.status : false,
      tourScheduleID: tourPackage ? tourPackage.tourScheduleID : '',
    },
  });

  const startDate = watch('startDate')
  const endDate = watch('endDate')
  const flightsTo = watch('flightsTo')
  const returnFlights = watch('returnFlights')
  const [flightsToDialogVisible, setFlightsToDialogVisible] = useState(false)
  const [flightsReturnDialogVisible, setFlightsReturnDialogVisible] = useState(false)

  async function onSubmit(formValues) {
    console.log(`formValues:`, formValues);

    if (!isValid) {
      snack('Пожалуйста, проверьте что данные правильно заполнены!')
      return
    }

    try {
      const formData = toFormData(formValues)

      if (tourPackage) {
        await tourPackagesService.put(id, formData)
        snack("Турпакет обновлен успешно!");
        setTimeout(() => {
          navigate(`/admin/tour-packages/view/${tourPackage.id}`);
        }, 500);
      } else {
        const { data: createdTourPackage } = await tourPackagesService.post(formData)
        console.log("createdTourPackage:", createdTourPackage);

        snack("Турпакет создан успешно!");
        setTimeout(() => {
          navigate(`/admin/tour-packages/view/${createdTourPackage.id}`);
        }, 500);
      }
    } catch (ex) {
      console.error(ex)
      snack(ex?.message)
    }
  }

  function displayErrors(type) {
    // For all required type
    if (errors && errors[type] && errors[type].type === 'required') {
      return `Заполните поле`
    }

    if (type === 'price') {
      if (errors.price) {
        if (errors.price.type === 'min') {
          return `Цена не может быть меньше 100$`
        }
      }
    }
    return null
  }

  function onFlightSelected(flight, direction) {
    if (direction === 'to') {
      setFlightsToDialogVisible(false)
      setValue('flightsTo', [...getValues('flightsTo'), flight])
    }
    if (direction === 'return') {
      setFlightsReturnDialogVisible(false)
      setValue('returnFlights', [...getValues('returnFlights'), flight])
    }
  }

  function removeFlight(flightIndex, direction) {
    console.log(`removeFlight: `, { flightIndex, direction });

    if (direction === "to") {
      const _flightsTo = getValues('flightsTo')
      setValue('flightsTo', _flightsTo.filter((_, inx) => flightIndex !== inx))
    }

    if (direction === "return") {
      setValue('returnFlights', getValues('returnFlights').filter((_, inx) => flightIndex !== inx))
    }
  }

  return (
    <CrudPage title={tourPackage ? 'Редактирование турпакета' : 'Создание турпакета'}>
      <form onSubmit={handleSubmit(onSubmit)} className="tour-form app-form">
        <div>
          <div className="preview">
            <label>Фото турпакета</label>
            {typeof getValues('photo') === "string" ? (
              <div style={{ width: 100, height: 'auto', marginTop: "1rem" }}>
                <img src={getValues('photo')} alt="" />
              </div>
            ) : null}
          </div>
          <TextField
            {...register('photo')}
            type="file"
            error={_.has(errors, 'photo')}
            helperText={displayErrors('photo')}
            placeholder="Фото турпакета"
            onChange={(e) => {
              const file = e.target.files[0]
              const max = 5 * 1000 * 1000; // 5MB
              console.log("File:", file);
              if (file && file.size > max) {
                snack(
                  "Вы не можете загружать большие файлы! Пожалуйста используйте файл маленького размера"
                );
              }
              const fileReader = new FileReader();
              const previewNode = document.querySelector('.tour-form .preview')
              if (!previewNode.querySelector('img')) {
                const img = document.createElement('img')
                const imgWrapper = document.createElement('div')
                imgWrapper.style = 'width: 100px; height: auto; margin-top: 1rem;'
                imgWrapper.appendChild(img)
                previewNode.appendChild(imgWrapper)
              }
              console.log("document.parentElement:", previewNode);

              const previewImage = previewNode.querySelector('img')

              fileReader.onload = event => {
                previewImage.setAttribute('src', event.target.result);
              }
              fileReader.readAsDataURL(file);
            }}
          />
        </div>
        <div>
          <TextField
            fullWidth {...register('name', { required: true })}
            type='text'
            label='Название турпакета *'
            error={_.has(errors, 'name')}
            helperText={displayErrors('name')}
          ></TextField>
        </div>
        <div>
          <TextField
            aria-invalid="true"
            fullWidth {...register('description', { required: true })}
            type='text'
            label='Краткое описание *'
            multiline rows={3}
            error={_.has(errors, 'description')}
            helperText={displayErrors('description')}
          ></TextField>
        </div>
        <div>
          <TextField
            fullWidth {...register('price', { required: true, min: 100 })}
            type='number'
            label='*Стоимость тура(в долларах) для взрослого'
            error={_.has(errors, 'price')}
            helperText={displayErrors('price')}
          ></TextField>
        </div>
        <div>
          <TextField
            fullWidth {...register('childPrice', { required: true, min: 100 })}
            type='number'
            label='*Стоимость тура(в долларах) для ребенка'
            error={_.has(errors, 'childPrice')}
            helperText={displayErrors('childPrice')}
          ></TextField>
        </div>
        <div>
          <TextField
            fullWidth {...register('infantPrice', { required: true, min: 100 })}
            type='number'
            label='*Стоимость тура(в долларах) для младенца'
            error={_.has(errors, 'infantPrice')}
            helperText={displayErrors('infantPrice')}
          ></TextField>
        </div>
        <section id="flightsTo" className="m-t-32">
          <h3 className="m-b-16">Рейсы туда</h3>
          <div>
            <TextField
              helperText={displayErrors('startDate')}
              label="Дата туда"
              error={_.has(errors, 'startDate')}
              fullWidth
              {...register('startDate', { required: true })}
              type='date'
              inputProps={{ min: startDate }}
            ></TextField>
          </div>
          <div className="m-t-16">
            <Table>
              <TableHead>
                <TableCell>ID Расписания</TableCell>
                <TableCell>Номер рейса</TableCell>
                <TableCell>Направление</TableCell>
                <TableCell>Дата вылета</TableCell>
                <TableCell>Дата прилета</TableCell>
                <TableCell>Длительность</TableCell>
                <TableCell></TableCell>
              </TableHead>
              {flightsTo?.map((f, flightIndex) => {
                console.log(`FF: `, f);

                return <TableRow className="">
                  <TableCell>{_.get(f, 'id')}</TableCell>
                  <TableCell>{_.get(f, 'flightNumber')}</TableCell>
                  <TableCell>{f.departureAirport.airportCode}-{f.arrivalAirport.airportCode}</TableCell>
                  <TableCell>{_.get(f, 'TourToFlight.departureDate')}</TableCell>
                  <TableCell>{_.get(f, 'TourToFlight.arrivalDate')}</TableCell>
                  <TableCell>
                    {`${f.durationHours} ч ${f.durationMinutes} мин`}
                  </TableCell>
                  <TableCell>
                    <Button type="button" onClick={() => removeFlight(flightIndex, 'to')} variant="outlined">Удалить</Button>
                  </TableCell>
                </TableRow>
              })}
            </Table>
          </div>
          <div className="m-t-16">
            <Button onClick={() => setFlightsToDialogVisible(true)}>Добавить рейс</Button>
          </div>
        </section>
        <div className="m-t-32">
          <h3 className="m-b-16">Рейсы обратно</h3>
          <div>
            <TextField
              helperText={displayErrors('endDate')}
              error={_.has(errors, 'endDate')}
              fullWidth {...register('endDate', { required: true })}
              type='date'
              inputProps={{ min: getValues('startDate') }}
            ></TextField>
          </div>
          <Table>
            <TableHead>
              <TableCell>ID Расписания</TableCell>
              <TableCell>Номер рейса</TableCell>
              <TableCell>Направление</TableCell>
              <TableCell>Дата вылета</TableCell>
              <TableCell>Дата прилета</TableCell>
              <TableCell>Длительность</TableCell>
              <TableCell></TableCell>
            </TableHead>
            {returnFlights?.map((f, flightIndex) => {
              return <TableRow className="">
                <TableCell>{_.get(f, 'id')}</TableCell>
                <TableCell>{_.get(f, 'flightNumber')}</TableCell>
                <TableCell>{f.departureAirport.airportCode}-{f.arrivalAirport.airportCode}</TableCell>
                <TableCell>{_.get(f, 'TourToFlight.departureDate')}</TableCell>
                <TableCell>{_.get(f, 'TourToFlight.arrivalDate')}</TableCell>
                <TableCell>{`${f.durationHours} ч ${f.durationMinutes} мин`}</TableCell>
                <TableCell>
                  <Button type="button" onClick={() => removeFlight(flightIndex, 'return')}>Удалить</Button>
                </TableCell>
              </TableRow>
            })}
          </Table>
          <div className="m-t-16">
            <Button onClick={() => setFlightsReturnDialogVisible(true)}>Добавить рейс</Button>
          </div>
        </div>

        <section>
          <h3>Проживание</h3>
          <div>
            <Controller
              control={control}
              name="hotels"
              rules={{ required: true }}
              render={({ field }) => {
                return <Autocomplete
                  value={field.value ? field.value.map(id => {
                    const f = hotels.data.find(m => m.id === id)
                    return {
                      id: f.id,
                      name: f.name
                    }
                  }) : []}
                  className="m-t-16"
                  multiple
                  options={hotels.data.map(f => ({ id: f.id, name: f.name }))}
                  getOptionLabel={(o) => o.name}
                  isOptionEqualToValue={(o, v) => {
                    console.log(`E:`, { o, v });
                    return o.id === v.id
                  }}
                  renderInput={(params) => <TextField
                    helperText={displayErrors('hotels')}
                    error={_.has(errors, 'hotels')}
                    {...params} label="Выбрать отели"
                  />}
                  onChange={(e, v) => {
                    console.log(`V:`, v)
                    field.onChange(v.map(x => x.id))
                  }}
                ></Autocomplete>
              }}
            ></Controller>
          </div>
        </section>

        <section>
          <h3>Пожалуйста, выберите подходящие атрибуты из списка</h3>
          <div>
            <Controller
              control={control}
              name="attributes"
              rules={{ required: true }}
              render={({ field }) => {
                return <Autocomplete
                  value={field.value ? field.value.map(id => {
                    const f = attributes.data.find(m => m.id === id)
                    return {
                      id: f.id,
                      name: f.name
                    }
                  }) : []}
                  className="m-t-16"
                  multiple
                  options={attributes.data.map(f => ({ id: f.id, name: f.name }))}
                  getOptionLabel={(o) => o.name}
                  isOptionEqualToValue={(o, v) => {
                    console.log(`E:`, { o, v });
                    return o.id === v.id
                  }}
                  renderInput={(params) => <TextField
                    helperText={displayErrors('attributes')}
                    error={_.has(errors, 'attributes')}
                    {...params}
                    label="Выберите атрибут" />}
                  onChange={(e, v) => {
                    console.log(`V:`, v)
                    field.onChange(v.map(x => x.id))
                  }}
                ></Autocomplete>
              }}
            ></Controller>
          </div>
        </section>

        <section>
          <Controller
            control={control}
            name="tourScheduleID"
            render={({ field }) => {
              return <FormControl fullWidth>
                <InputLabel>Выберите расписание</InputLabel>
                <Select
                  value={field.value}
                  onChange={field.onChange}
                  className="w-100"
                  label="Выберите расписание"
                >
                  {tourSchedules.data.map(s => {
                    return <MenuItem value={s.id}>{s.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            }}
          ></Controller>
        </section>

        <div className="flex gap-8">
          <input type="checkbox" {...register('status')} id="status" />
          <label className="m-l-8" htmlFor="status">Отображать на сайте</label>
        </div>

        <Dialog fullWidth maxWidth="lg" open={flightsToDialogVisible} onClose={() => { setFlightsToDialogVisible(false) }}>
          <FlightsDialog onSelect={(f) => onFlightSelected(f, 'to')} startDate={startDate} schedules={flights.data}></FlightsDialog>
        </Dialog>

        <Dialog fullWidth maxWidth="lg" open={flightsReturnDialogVisible} onClose={() => { setFlightsReturnDialogVisible(false) }}>
          <FlightsDialog onSelect={(f) => onFlightSelected(f, 'return')} startDate={endDate} schedules={flights.data}></FlightsDialog>
        </Dialog>

        <div className="m-t-32 m-b-32">
          <Button type="submit" variant="contained">
            {tourPackage ? `Обновить турпакет` : `Создать турпакет`}
          </Button>
        </div>

        {/* <pre className="m-t-32">
          {JSON.stringify(watch(), null, 4)}
        </pre> */}
      </form>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(TourPackageForm, (router) => {
    let requests = {
      attributes: () => attributesService.getAll(),
      hotels: () => hotelsService.getAll(),
      flights: () => flightsService.getAll(),
      tourSchedules: () => tourScheduleService.getAll()
    }
    if (router.params.id) {
      requests['tourPackage'] = () => tourPackagesService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
