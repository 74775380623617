import { Outlet } from "react-router-dom";
import { SupportList } from "./SupportList";

export const supportsRoutes = [
  {
    path: "supports",
    element: Outlet,
    children: [
      {
        path: "",
        element: SupportList,
      },
    ],
  },
];
